import React from "react";
import styled from "styled-components";
import { breakpoints, colors, spacers } from "../styles/variables";

const TitleStyled = styled.h1`
  font: 34px / 41px "Helvetica Neue Bold";
  color: ${colors.title};
  margin-bottom: ${spacers.tablet}px;
  max-width: 900px;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    font: 51px / 62px "Helvetica Neue Bold";
    margin-bottom: 45px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 68px / 83px "Helvetica Neue Bold";
    margin-bottom: ${spacers.desktop}px;
  }
`;

const Title: React.FC = ({ children }) => {
  return <TitleStyled>{children}</TitleStyled>;
};

export default Title;
