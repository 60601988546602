/* eslint-disable max-len */

export const colors = {
  //HICC
  red: "#D1111C",
  grey: "#353C4C",
  title: "#990C1A",
  text: "#480000",
  backgroundPink: "#F5E6E7",
  backgroundGrey: "#F7F7F7",

  //  //
  //  brand: "#D1111C",
  //  lilac: "#9d7cbf",
  //  accent: "#ffb238",
  //  success: "#37b635",
  //  warning: "#ec1818",
  //  ui: {
  //    bright: "#e0d6eb",
  //    light: "#f5f3f7",
  //    whisper: "#fbfafc",
  //  },
  //  code: "#fcf6f0",
  //  gray: {
  //    dark: "hsla(270, 17.119554496%, 0%, 0.92)",
  //    copy: "hsla(270, 15.797828016000002%, 0%, 0.88)",
  //    calm: "rgba(0, 0, 0, 0.54)",
  //  },
  //  white: "#fff",
  //  black: "#000",
};

export const fonts = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const breakpoints = {
  //rm? start
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  //rm? end

  tablet: 768,
  desktop: 1280,
};

export const widths = {
  //rm? start
  md: 720,
  lg: 960,
  xl: 1140,
  //rm? end

  //Widths HICC
  textContentMax: 800,
  contentMax: 1792,
};

export const spacers = {
  mobile: 16,
  tablet: 32,
  desktop: 64,
};

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  containerPadding: 1.5,
};

export const heights = {
  header: {
    mobile: 64,
    desktop: 94,
  },
};
